const Orange = {
  orange: "#e67e22",
  "orange-50": "#fdf2e9",
  "orange-100": "#fae5d3",
  "orange-200": "#f5cba7",
  "orange-300": "#f0b27a",
  "orange-400": "#eb984e",
  "orange-500": "#e67e22",
  "orange-600": "#ca6f1e",
  "orange-700": "#af601a",
  "orange-800": "#935116",
  "orange-900": "#784212",
};

export default Orange;

import React, { useState, useEffect } from "react";
import "../../styles/home.css";
import Varun from "../../assets/images/varun.JPEG";
import $ from "jquery";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Banner from "../Banner";
import CustomBanner from "../Custom-Banner";
import Badge from "../Badges";
import CustomBadge from "../Custom-Badge";
import Toast from "../Toast";
import CustomToast from "../Custom-Toast";
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.min.css";
import Red from "../../colors/red";
import Pink from "../../colors/pink";
import Purple from "../../colors/purple";
import Blue from "../../colors/blue";
import Green from "../../colors/green";
import Yellow from "../../colors/yellow";
import Orange from "../../colors/orange";
import Gray from "../../colors/gray";
import Donut from "../Donut";
import Kulfi from "../Kulfi";

function Home() {
  const [color, setColor] = useState("red");

  useEffect(() => {
    const linkTag = document.createElement("link");
    linkTag.href =
      "https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";
    linkTag.rel = "stylesheet";
    document.head.appendChild(linkTag);
  }, []);

  function scrollToClone(id, tops) {
    $("html, body").animate(
      {
        scrollTop: $(id).offset().top - tops,
      },
      800
    );
  }

  return (
    <>
      <div className="home-container">
        <div className="header-for-create">
          Create banners, badges, toasts and more with{" "}
          <span className="speed">speed.</span>
        </div>
        <div className="desc">
          vbocxjs is a ReactJS library for creating banners, badges, toasts
          and more with zero dependency. It uses inline styling by reducing the amount of
          look up time for the CSS engine.
        </div>
        <div className="get-git-holder">
          <div
            className="get-started-button"
            onClick={() => {
              scrollToClone("#documentation", 100);
            }}
          >
            Get Started
          </div>
          <a
            style={{ textDecoration: "none" }}
            target="blank"
            href="https://github.com/varunpbardwaj/vbocxjs"
          >
            <div className="github-button">
              <i className="fa fa-github"></i> Github
            </div>
          </a>
        </div>
        <div className="d-n-c-img-holder">
          <div className="d-n-c-by">DESIGNED AND CODED BY </div>
          <img src={Varun} alt="" width="35px;" className="varun-img" />
          <div className="d-n-c-varun">Varun Bardwaj</div>
        </div>
      </div>
      <div id="documentation" className="documentation">
        <h1 className="getting-started">Getting Started</h1>
        <p style={{ marginTop: "30px", fontWeight: "300", fontSize: "17px" }}>
          Install vbocxjs
        </p>
        <SyntaxHighlighter
          id="npmInstall"
          showLineNumbers={false}
          showInlineLineNumbers={false}
          language="javascript"
          style={atomOneDark}
          className="highlighter"
        >
          {`npm install vbocxjs\n                   or\n     yarn add vbocxjs`}
        </SyntaxHighlighter>

        <div className="sectionHolder">
        <div
            className="scrollButton"
            onClick={() => {
              scrollToClone("#Badge", 150);
            }}
          >
            Badges
          </div>
          <div
            className="scrollButton"
            onClick={() => {
              scrollToClone("#Banner", 150);
            }}
          >
            Banners
          </div>
          <div
            className="scrollButton"
            onClick={() => {
              scrollToClone("#Colors", 100);
            }}
          >
            Colors
          </div>
          <div
            className="scrollButton"
            onClick={() => {
              scrollToClone("#Donut", 150);
            }}
          >
            Progress
          </div>
          <div
            className="scrollButton"
            onClick={() => {
              scrollToClone("#Toast", 170);
            }}
          >
            Toasts
          </div>
        </div>

        <h3
          style={{
            marginTop: "80px",
            marginBottom: "30px",
            fontWeight: "600",
            fontSize: "1.9rem",
          }}
        >
          Badges
        </h3>

        <div className="banner-img-all" id="Badge">
          <div
            style={{
              marginBottom: "50px",
              padding: "20px 20px 0px 20px",
              border: "1px solid #eeeeee",
              borderRadius: "10px",
            }}
          >
            <Badge type="solid" variant="default" message="solid" />
            <Badge type="outline" variant="default" message="outline" />
            <div>
              <SyntaxHighlighter
                showLineNumbers={false}
                showInlineLineNumbers={false}
                language="javascript"
                style={atomOneDark}
                className="highlighter"
              >
                {`import { Badge } from "vbocxjs";\n\n    <Badge\n\ttype="solid"\n\tvariant="default"\n\tmessage="solid"\n    />\n\n    <Badge\n\ttype="outline"\n\tvariant="default"\n\tmessage="outline"\n    />`}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>

        <div className="banner-img-all">
          <div
            style={{
              marginBottom: "50px",
              padding: "20px 20px 0px 20px",
              border: "1px solid #eeeeee",
              borderRadius: "10px",
            }}
          >
            <Badge type="solid" variant="info" message="solid" />
            <Badge type="outline" variant="info" message="outline" />
            <div>
              <SyntaxHighlighter
                showLineNumbers={false}
                showInlineLineNumbers={false}
                language="javascript"
                style={atomOneDark}
                className="highlighter"
              >
                {`import { Badge } from "vbocxjs";\n\n    <Badge\n\ttype="solid"\n\tvariant="info"\n\tmessage="solid"\n    />\n\n    <Badge\n\ttype="outline"\n\tvariant="info"\n\tmessage="outline"\n    />`}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>

        <div className="banner-img-all">
          <div
            style={{
              marginBottom: "50px",
              padding: "20px 20px 0px 20px",
              border: "1px solid #eeeeee",
              borderRadius: "10px",
            }}
          >
            <Badge type="solid" variant="success" message="solid" />
            <Badge type="outline" variant="success" message="outline" />
            <div>
              <SyntaxHighlighter
                showLineNumbers={false}
                showInlineLineNumbers={false}
                language="javascript"
                style={atomOneDark}
                className="highlighter"
              >
                {`import { Badge } from "vbocxjs";\n\n    <Badge\n\ttype="solid"\n\tvariant="success"\n\tmessage="solid"\n    />\n\n    <Badge\n\ttype="outline"\n\tvariant="success"\n\tmessage="outline"\n    />`}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>

        <div className="banner-img-all">
          <div
            style={{
              marginBottom: "50px",
              padding: "20px 20px 0px 20px",
              border: "1px solid #eeeeee",
              borderRadius: "10px",
            }}
          >
            <Badge type="solid" variant="warning" message="solid" />
            <Badge type="outline" variant="warning" message="outline" />
            <div>
              <SyntaxHighlighter
                showLineNumbers={false}
                showInlineLineNumbers={false}
                language="javascript"
                style={atomOneDark}
                className="highlighter"
              >
                {`import { Badge } from "vbocxjs";\n\n    <Badge\n\ttype="solid"\n\tvariant="warning"\n\tmessage="solid"\n    />\n\n    <Badge\n\ttype="outline"\n\tvariant="warning"\n\tmessage="outline"\n    />`}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>

        <div className="banner-img-all">
          <div
            style={{
              marginBottom: "50px",
              padding: "20px 20px 0px 20px",
              border: "1px solid #eeeeee",
              borderRadius: "10px",
            }}
          >
            <Badge type="solid" variant="error" message="solid" />
            <Badge type="outline" variant="error" message="outline" />
            <div>
              <SyntaxHighlighter
                showLineNumbers={false}
                showInlineLineNumbers={false}
                language="javascript"
                style={atomOneDark}
                className="highlighter"
              >
                {`import { Badge } from "vbocxjs";\n\n    <Badge\n\ttype="solid"\n\tvariant="error"\n\tmessage="solid"\n    />\n\n    <Badge\n\ttype="outline"\n\tvariant="error"\n\tmessage="outline"\n    />`}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>

        <h3
          id="Custom-Badge"
          style={{
            marginTop: "80px",
            marginBottom: "30px",
            fontWeight: "600",
            fontSize: "1.9rem",
          }}
        >
          Custom Badge
        </h3>

        <div className="banner-img-all">
          <div
            style={{
              marginBottom: "50px",
              padding: "20px 20px 0px 20px",
              border: "1px solid #eeeeee",
              borderRadius: "10px",
            }}
          >
            <CustomBadge
              type="solid"
              message="solid"
              backgroundColor={Purple["purple-400"]}
              color="#FFFFFF"
            />
            <label style={{ padding: "0px 0px 0px 10px" }}>
              <CustomBadge
                type="outline"
                message="outline"
                color={Orange["orange-400"]}
              />
            </label>
            <div>
              <SyntaxHighlighter
                showLineNumbers={false}
                showInlineLineNumbers={false}
                language="javascript"
                style={atomOneDark}
                className="highlighter"
              >
                {`import { CustomBadge, Colors } from "vbocxjs";\n\n    <CustomBadge\n\ttype="solid"\n\tmessage="solid"\n\tbackgroundColor={Colors["purple-400"]}\n\tcolor={Colors["white"]}\n    />\n\n    <CustomBadge\n\ttype="outline"\n\tmessage="outline"\n\tcolor={Orange["orange-400"]}\n    />`}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>

        <label
          style={{
            marginTop: "30px",
            marginBottom: "25px",
            fontWeight: "600",
            fontSize: "1.5rem",
          }}
        >
          Props
        </label>
        <br />

        <label
          style={{
            marginTop: "10px",
            marginBottom: "25px",
            fontWeight: "600",
            fontSize: "1.2rem",
          }}
        >
          Badges Props
        </label>

        <div>
          <Table size="sm" responsive style={{ color: "rgba(0, 0, 0, 0.7)" }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Mandatory</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>type</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    solid
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    outline
                  </label>
                </td>
                <td>yes</td>
                <td>Display type of the badge.</td>
              </tr>
              <tr>
                <td>variant</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    default
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    info
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    success
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    warning
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    error
                  </label>
                </td>
                <td>yes</td>
                <td>The variant of the badge style to use.</td>
              </tr>
              <tr>
                <td>message</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    string
                  </label>
                </td>
                <td>yes</td>
                <td>Message to be displayed.</td>
              </tr>
            </tbody>
          </Table>
        </div>

        <label
          style={{
            marginTop: "25px",
            marginBottom: "25px",
            fontWeight: "600",
            fontSize: "1.2rem",
          }}
        >
          Custom Badge Props
        </label>

        <div>
          <Table size="sm" responsive style={{ color: "rgba(0, 0, 0, 0.7)" }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Mandatory</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>type</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    solid
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    outline
                  </label>
                </td>
                <td>yes</td>
                <td>Display type of the badge.</td>
              </tr>
              <tr>
                <td>message</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    string
                  </label>
                </td>
                <td>yes</td>
                <td>Message to be displayed.</td>
              </tr>
              <tr>
                <td>backgroundColor</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    string
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    Colors[value]
                  </label>
                </td>
                <td>yes(solid), no(outline)</td>
                <td>Background color of badge.</td>
              </tr>
              <tr>
                <td>color</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    string
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    Colors[value]
                  </label>
                </td>
                <td>yes</td>
                <td>Font Color of Badge.</td>
              </tr>
            </tbody>
          </Table>
        </div>

        <h3
          style={{
            marginTop: "70px",
            marginBottom: "30px",
            fontWeight: "600",
            fontSize: "1.9rem",
          }}
        >
          Banners
        </h3>

        <div className="banner-img-all" id="Banner">
          <div
            style={{
              marginBottom: "50px",
              padding: "20px 20px 0px 20px",
              border: "1px solid #eeeeee",
              borderRadius: "10px",
            }}
          >
            <Banner variant="default" message="This is a Default Banner." />
            <div>
              <SyntaxHighlighter
                showLineNumbers={false}
                showInlineLineNumbers={false}
                language="javascript"
                style={atomOneDark}
                className="highlighter"
              >
                {`import { Banner } from "vbocxjs";\n\n    <Banner\n\tvariant="default"\n\tmessage="This is a Default Banner."\n    />`}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>

        <div className="banner-img-all">
          <div
            style={{
              marginBottom: "50px",
              padding: "20px 20px 0px 20px",
              border: "1px solid #eeeeee",
              borderRadius: "10px",
            }}
          >
            <Banner variant="info" message="This is an Info Banner." />
            <div>
              <SyntaxHighlighter
                showLineNumbers={false}
                showInlineLineNumbers={false}
                language="javascript"
                style={atomOneDark}
                className="highlighter"
              >
                {`import { Banner } from "vbocxjs";\n\n    <Banner\n\tvariant="info"\n\tmessage="This is an Info Banner."\n    />`}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>

        <div className="banner-img-all">
          <div
            style={{
              marginBottom: "50px",
              padding: "20px 20px 0px 20px",
              border: "1px solid #eeeeee",
              borderRadius: "10px",
            }}
          >
            <Banner variant="success" message="This is a Success Banner." />
            <div>
              <SyntaxHighlighter
                showLineNumbers={false}
                showInlineLineNumbers={false}
                language="javascript"
                style={atomOneDark}
                className="highlighter"
              >
                {`import { Banner } from "vbocxjs";\n\n    <Banner\n\tvariant="success"\n\tmessage="This is a Success Banner."\n    />`}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>

        <div className="banner-img-all">
          <div
            style={{
              marginBottom: "50px",
              padding: "20px 20px 0px 20px",
              border: "1px solid #eeeeee",
              borderRadius: "10px",
            }}
          >
            <Banner variant="warning" message="This is a Warning Banner." />
            <div>
              <SyntaxHighlighter
                showLineNumbers={false}
                showInlineLineNumbers={false}
                language="javascript"
                style={atomOneDark}
                className="highlighter"
              >
                {`import { Banner } from "vbocxjs";\n\n    <Banner\n\tvariant="warning"\n\tmessage="This is a Warning Banner."\n    />`}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>

        <div className="banner-img-all">
          <div
            style={{
              marginBottom: "50px",
              padding: "20px 20px 0px 20px",
              border: "1px solid #eeeeee",
              borderRadius: "10px",
            }}
          >
            <Banner variant="error" message="This is an Error Banner." />
            <div>
              <SyntaxHighlighter
                showLineNumbers={false}
                showInlineLineNumbers={false}
                language="javascript"
                style={atomOneDark}
                className="highlighter"
              >
                {`import { Banner } from "vbocxjs";\n\n    <Banner\n\tvariant="error"\n\tmessage="This is an Error Banner."\n    />`}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>

        <div className="banner-img-all" id="Custom-Banner">
          <div
            style={{
              marginBottom: "50px",
              padding: "20px 20px 0px 20px",
              border: "1px solid #eeeeee",
              borderRadius: "10px",
            }}
          >
            <CustomBanner
              message="This is a Custom Banner."
              backgroundColor="#d527ad"
              color="#FFFFFF"
              bannerIcon={<i className="fa fa-heart" aria-hidden="true"></i>}
              closeIcon={<i className="fa fa-times" aria-hidden="true"></i>}
            />
            <div>
              <SyntaxHighlighter
                showLineNumbers={false}
                showInlineLineNumbers={false}
                language="javascript"
                style={atomOneDark}
                className="highlighter"
              >
                {`import { CustomBanner, Colors } from "vbocxjs";\n\n    <CustomBanner\n\tmessage="This is a Custom Banner."\n\tbackgroundColor={Colors["pink-600"]}\n\tcolor="#FFFFFF"\n\tbannerIcon={<i className="fa fa-heart" aria-hidden="true"></i>}\n\tcloseIcon={<i className="fa fa-times" aria-hidden="true"></i>}\n    />`}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>

        <label
          style={{
            marginTop: "30px",
            marginBottom: "25px",
            fontWeight: "600",
            fontSize: "1.5rem",
          }}
        >
          Props
        </label>
        <br />

        <label
          style={{
            marginTop: "10px",
            marginBottom: "25px",
            fontWeight: "600",
            fontSize: "1.2rem",
          }}
        >
          Banner Props
        </label>

        <div>
          <Table size="sm" responsive style={{ color: "rgba(0, 0, 0, 0.7)" }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Mandatory</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>variant</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    default
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    info
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    success
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    warning
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    error
                  </label>
                </td>
                <td>yes</td>
                <td>The variant of the banner style to use.</td>
              </tr>
              <tr>
                <td>position</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    top
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    middle
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    bottom
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    XX%
                  </label>
                </td>
                <td>no</td>
                <td>Popup out position of the banner.</td>
              </tr>
              <tr>
                <td>message</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    string
                  </label>
                </td>
                <td>yes</td>
                <td>Message to be displayed.</td>
              </tr>
              <tr>
                <td>timer</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    number
                  </label>
                </td>
                <td>no</td>
                <td>Display time of banner in milliseconds.</td>
              </tr>
              <tr>
                <td>animate</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    fade-in-out
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    zoom-in-out
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    stretch-in-out
                  </label>
                </td>
                <td>no</td>
                <td>Popup out animation of the banner.</td>
              </tr>
            </tbody>
          </Table>
        </div>

        <label
          style={{
            marginTop: "30px",
            marginBottom: "25px",
            fontWeight: "600",
            fontSize: "1.2rem",
          }}
        >
          Custom Banner Props
        </label>

        <div>
          <Table size="sm" responsive style={{ color: "rgba(0, 0, 0, 0.7)" }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Mandatory</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>position</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    top
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    middle
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    bottom
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    XX%
                  </label>
                </td>
                <td>no</td>
                <td>Popup out position of the banner.</td>
              </tr>
              <tr>
                <td>message</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    string
                  </label>
                </td>
                <td>yes</td>
                <td>Message to be displayed.</td>
              </tr>
              <tr>
                <td>backgroundColor</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    string
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    Colors[value]
                  </label>
                </td>
                <td>yes</td>
                <td>Background Color of banner.</td>
              </tr>
              <tr>
                <td>color</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    string
                  </label>
                </td>
                <td>yes</td>
                <td>Text Color of banner.</td>
              </tr>
              <tr>
                <td>bannerIcon</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    JSX.Element
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    Font Awesome Icons
                  </label>
                </td>
                <td>no</td>
                <td>Icon indicating banner type.</td>
              </tr>
              <tr>
                <td>closeIcon</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    JSX.Element
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    Font Awesome Icons
                  </label>
                </td>
                <td>no</td>
                <td>Icon replacing default close icon.</td>
              </tr>
              <tr>
                <td>timer</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    number
                  </label>
                </td>
                <td>no</td>
                <td>Display time of banner in milliseconds.</td>
              </tr>
              <tr>
                <td>animate</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    fade-in-out
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    zoom-in-out
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    stretch-in-out
                  </label>
                </td>
                <td>no</td>
                <td>Popup out animation of the banner.</td>
              </tr>
            </tbody>
          </Table>
        </div>

        <h3
          style={{
            marginTop: "80px",
            marginBottom: "0px",
            fontWeight: "600",
            fontSize: "1.9rem",
          }}
        >
          Progress
        </h3>

        <div
          id="Donut"
          style={{
            marginTop: "20px",
            marginBottom: "25px",
            fontWeight: "600",
            fontSize: "1.2rem",
            opacity: 0.9,
          }}
        >
          Donut
        </div>

        <div className="banner-img-all">
          <div
            style={{
              marginBottom: "50px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: "20px 20px 20px 20px",
              border: "1px solid #eeeeee",
              borderRadius: "10px",
            }}
          >
            <div style={{ margin: "20px 30px 20px 0px" }}>
              <Donut
                size={80}
                percent={69}
                theme={[Pink["pink-500"], "#000000", Gray["gray-50"]]}
              />
            </div>
            <div style={{ margin: "20px 30px 20px 0px" }}>
              <Donut
                size={100}
                percent={32}
                theme={[Blue["blue-500"], "#000000", Gray["gray-50"]]}
                hidePercentText={true}
              />
            </div>
            <div style={{ margin: "20px 30px 20px 0px" }}>
              <Donut
                size={120}
                percent={54}
                theme={[Green["green-500"], "#000000", Gray["gray-50"]]}
                curvedEdge={true}
              />
            </div>
            <div style={{ margin: "20px 0px 20px 0px" }}>
              <Donut
                size={140}
                percent={80}
                gradient={["#12c2e9", "#c471ed", "", Gray["gray-50"]]}
                hidePercentText={true}
                curvedEdge={true}
              />
            </div>
            <div style={{ width: "100%" }}>
              <SyntaxHighlighter
                showLineNumbers={false}
                showInlineLineNumbers={false}
                language="javascript"
                style={atomOneDark}
                className="highlighter"
              >
                {`import { Donut, Colors } from "vbocxjs";\n\n    <Donut\n\tsize={80}\n\tpercent={69}"\n\ttheme={[Colors["pink-500"], Colors["black"], Colors["gray-50"]]}\n    />
    \n    <Donut\n\tsize={100}\n\tpercent={32}"\n\ttheme={[Colors["blue-500"], Colors["black"], Colors["gray-50"]]}\n\thidePercentText={true}    \n    />        
    \n    <Donut\n\tsize={120}\n\tpercent={54}"\n\ttheme={[Colors["green-500"], Colors["black"], Colors["gray-50"]]}\n\tcurvedEdge={true}    \n    />
    \n    <Donut\n\tsize={140}\n\tpercent={30}"\n\tgradient={["#12c2e9", "#c471ed", "", Colors["gray-50"]]}\n\thidePercentText={true}\n\tcurvedEdge={true}    \n     />
    \n      {/*\n\ttheme={[\n\t\tCompleted Background Color,\n\t\tPercentage Font Color,\n\t\tRemaining Background Color\n\t]}\n    */}
    \n      {/*\n\tgradient={[\n\t\tGradient 1 Color,\n\t\tGradient 2 Color,\n\t\tPercentage Font Color\n\t\tRemaining Background Color\n\t]}\n    */}
`}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>

        <div
          id="Kulfi"
          style={{
            marginTop: "20px",
            marginBottom: "25px",
            fontWeight: "600",
            fontSize: "1.2rem",
            opacity: 0.9,
          }}
        >
          Kulfi
        </div>

        <div className="banner-img-all">
          <div
            style={{
              marginBottom: "50px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: "20px 20px 20px 20px",
              border: "1px solid #eeeeee",
              borderRadius: "10px",
            }}
          >
            <div style={{ margin: "20px 30px 20px 0px" }}>
              <Kulfi
                length={350}
                percent={32}
                theme={["#3DB4EA", "#FFFFFF", Gray["gray-100"]]}
                hidePercentText={true}
              />
            </div>
            <div style={{ margin: "20px 30px 20px 0px" }}>
              <Kulfi
                length={600}
                percent={66}
                gradient={["#DE24B1", "#4C6ADD", "#FFFFFF", Gray["gray-100"]]}
              />
            </div>
            <div style={{ margin: "20px 30px 20px 0px" }}>
              <Kulfi
                length={500}
                percent={88}
                theme={["#D04427", "#FFFFFF", Gray["gray-100"]]}
                curvedEdge={true}
              />
            </div>
            <div style={{ width: "100%" }}>
              <SyntaxHighlighter
                showLineNumbers={false}
                showInlineLineNumbers={false}
                language="javascript"
                style={atomOneDark}
                className="highlighter"
              >
                {`import { Kulfi, Colors } from "vbocxjs";\n\n    <Kulfi\n\tlength={350}\n\tpercent={32}"\n\ttheme={["#3DB4EA", "#FFFFFF", Gray["gray-100"]]}\n\thidePercentText={true}\n    />
    \n    <Kulfi\n\tsize={600}\n\tpercent={66}"\n\tgradient={["#DE24B1", "#4C6ADD", "#FFFFFF", Gray["gray-100"]]}    \n    />
    \n    <Kulfi\n\tsize={500}\n\tpercent={88}"\n\ttheme={["#D04427", "#FFFFFF", Gray["gray-100"]]}\n    />
    \n      {/*\n\ttheme={[\n\t\tCompleted Background Color,\n\t\tPercentage Font Color,\n\t\tRemaining Background Color\n\t]}\n    */}
    \n      {/*\n\tgradient={[\n\t\tGradient 1 Color,\n\t\tGradient 2 Color,\n\t\tPercentage Font Color\n\t\tRemaining Background Color\n\t]}\n    */}
    `}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>

        <label
          style={{
            marginTop: "30px",
            marginBottom: "25px",
            fontWeight: "600",
            fontSize: "1.5rem",
          }}
        >
          Props
        </label>
        <br />

        <label
          style={{
            marginTop: "10px",
            marginBottom: "25px",
            fontWeight: "600",
            fontSize: "1.2rem",
          }}
        >
          Donut Props
        </label>

        <div>
          <Table size="sm" responsive style={{ color: "rgba(0, 0, 0, 0.7)" }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Mandatory</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>size</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    number
                  </label>
                </td>
                <td>yes</td>
                <td>The size of donut.</td>
              </tr>
              <tr>
                <td>percent</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    number
                  </label>
                </td>
                <td>yes</td>
                <td>Progressed Percentage.</td>
              </tr>
              <tr>
                <td>theme</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                   array
                  </label>
                </td>
                <td>yes(for flat background)</td>
                <td>Theme of donut(explained in component declaration).</td>
              </tr>
              <tr>
                <td>gradient</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    array
                  </label>
                </td>
                <td>yes(for gradient background)</td>
                <td>Gradient combination of donut(explained in component declaration).</td>
              </tr>
              <tr>
                <td>hidePercentText</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    boolean
                  </label>
                </td>
                <td>no</td>
                <td>Show/Hide donut percentage.</td>
              </tr>
              <tr>
                <td>curvedEdge</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    boolean
                  </label>
                </td>
                <td>no</td>
                <td>Rounded Corners of donut.</td>
              </tr>
            </tbody>
          </Table>
        </div>


        <label
          style={{
            marginTop: "10px",
            marginBottom: "25px",
            fontWeight: "600",
            fontSize: "1.2rem",
          }}
        >
          Kulfi Props
        </label>

        <div>
          <Table size="sm" responsive style={{ color: "rgba(0, 0, 0, 0.7)" }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Mandatory</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>length</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    number
                  </label>
                </td>
                <td>yes</td>
                <td>The length of kulfi.</td>
              </tr>
              <tr>
                <td>percent</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    number
                  </label>
                </td>
                <td>yes</td>
                <td>Progressed Percentage.</td>
              </tr>
              <tr>
                <td>theme</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                   array
                  </label>
                </td>
                <td>yes(for flat background)</td>
                <td>Theme of kulfi(explained in component declaration).</td>
              </tr>
              <tr>
                <td>gradient</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    array
                  </label>
                </td>
                <td>yes(for gradient background)</td>
                <td>Gradient combination of kulfi(explained in component declaration).</td>
              </tr>
              <tr>
                <td>hidePercentText</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    boolean
                  </label>
                </td>
                <td>no</td>
                <td>Show/Hide kulfi percentage.</td>
              </tr>
              <tr>
                <td>curvedEdge</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    boolean
                  </label>
                </td>
                <td>no</td>
                <td>Rounded Corners of kulfi.</td>
              </tr>
            </tbody>
          </Table>
        </div>


        <h3
          style={{
            marginTop: "80px",
            marginBottom: "30px",
            fontWeight: "600",
            fontSize: "1.9rem",
          }}
        >
          Toasts
        </h3>

        <div className="banner-img-all" id="Toast">
          <div
            style={{
              marginBottom: "50px",
              padding: "20px 20px 0px 20px",
              border: "1px solid #eeeeee",
              borderRadius: "10px",
            }}
          >
            <Toast variant="default" message="This is a Default Toast." />
            <div>
              <SyntaxHighlighter
                showLineNumbers={false}
                showInlineLineNumbers={false}
                language="javascript"
                style={atomOneDark}
                className="highlighter"
              >
                {`import { Toast } from "vbocxjs";\n\n    <Toast\n\tvariant="default"\n\tmessage="This is a Default Toast."\n    />`}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>

        <div className="banner-img-all">
          <div
            style={{
              marginBottom: "50px",
              padding: "20px 20px 0px 20px",
              border: "1px solid #eeeeee",
              borderRadius: "10px",
            }}
          >
            <Toast variant="info" message="This is an Info Toast." />
            <div>
              <SyntaxHighlighter
                showLineNumbers={false}
                showInlineLineNumbers={false}
                language="javascript"
                style={atomOneDark}
                className="highlighter"
              >
                {`import { Toast } from "vbocxjs";\n\n    <Toast\n\tvariant="info"\n\tmessage="This is an Info Toast."\n    />`}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>

        <div className="banner-img-all">
          <div
            style={{
              marginBottom: "50px",
              padding: "20px 20px 0px 20px",
              border: "1px solid #eeeeee",
              borderRadius: "10px",
            }}
          >
            <Toast variant="success" message="This is a Success Toast." />
            <div>
              <SyntaxHighlighter
                showLineNumbers={false}
                showInlineLineNumbers={false}
                language="javascript"
                style={atomOneDark}
                className="highlighter"
              >
                {`import { Toast } from "vbocxjs";\n\n    <Toast\n\tvariant="success"\n\tmessage="This is a Success Toast."\n    />`}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>

        <div className="banner-img-all">
          <div
            style={{
              marginBottom: "50px",
              padding: "20px 20px 0px 20px",
              border: "1px solid #eeeeee",
              borderRadius: "10px",
            }}
          >
            <Toast variant="warning" message="This is a Warning Toast." />
            <div>
              <SyntaxHighlighter
                showLineNumbers={false}
                showInlineLineNumbers={false}
                language="javascript"
                style={atomOneDark}
                className="highlighter"
              >
                {`import { Toast } from "vbocxjs";\n\n    <Toast\n\tvariant="warning"\n\tmessage="This is a Warning Toast."\n    />`}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>

        <div className="banner-img-all">
          <div
            style={{
              marginBottom: "50px",
              padding: "20px 20px 0px 20px",
              border: "1px solid #eeeeee",
              borderRadius: "10px",
            }}
          >
            <Toast variant="error" message="This is an Error Toast." />
            <div>
              <SyntaxHighlighter
                showLineNumbers={false}
                showInlineLineNumbers={false}
                language="javascript"
                style={atomOneDark}
                className="highlighter"
              >
                {`import { Toast } from "vbocxjs";\n\n    <Toast\n\tvariant="error"\n\tmessage="This is an Error Toast."\n    />`}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>

        <div className="banner-img-all" id="Custom-Toast">
          <div
            style={{
              marginBottom: "50px",
              padding: "20px 20px 0px 20px",
              border: "1px solid #eeeeee",
              borderRadius: "10px",
            }}
          >
            <CustomToast
              message="This is a Custom Toast."
              backgroundColor="#D527AD"
              color="#ffffff"
              toastIcon={<i className="fa fa-heart" aria-hidden="true"></i>}
              closeIcon={<i className="fa fa-times" aria-hidden="true"></i>}
            />
            <div>
              <SyntaxHighlighter
                showLineNumbers={false}
                showInlineLineNumbers={false}
                language="javascript"
                style={atomOneDark}
                className="highlighter"
              >
                {`import { CustomToast, Colors } from "vbocxjs";\n\n    <CustomToast\n\tmessage="This is a Custom Toast."\n\tbackgroundColor={Colors["pink-600"]}\n\tcolor="#FFFFFF"\n\ttoastIcon={<i className="fa fa-heart" aria-hidden="true"></i>}\n\tcloseIcon={<i className="fa fa-times" aria-hidden="true"></i>}\n    />`}
              </SyntaxHighlighter>
            </div>
          </div>
        </div>

        <label
          style={{
            marginTop: "30px",
            marginBottom: "25px",
            fontWeight: "600",
            fontSize: "1.5rem",
          }}
        >
          Props
        </label>
        <br />

        <label
          style={{
            marginTop: "10px",
            marginBottom: "25px",
            fontWeight: "600",
            fontSize: "1.2rem",
          }}
        >
          Toast Props
        </label>

        <div>
          <Table size="sm" responsive style={{ color: "rgba(0, 0, 0, 0.7)" }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Mandatory</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>variant</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    default
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    info
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    success
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    warning
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    error
                  </label>
                </td>
                <td>yes</td>
                <td>The variant of the toast style to use.</td>
              </tr>
              <tr>
                <td>position</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    top
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    middle
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    bottom
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    XX%
                  </label>
                </td>
                <td>no</td>
                <td>Popup out position of the toast.</td>
              </tr>
              <tr>
                <td>message</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    string
                  </label>
                </td>
                <td>yes</td>
                <td>Message to be displayed.</td>
              </tr>
              <tr>
                <td>timer</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    number
                  </label>
                </td>
                <td>no</td>
                <td>Display time of toast in milliseconds.</td>
              </tr>
              <tr>
                <td>animate</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    fade-in-out
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    zoom-in-out
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    stretch-in-out
                  </label>
                </td>
                <td>no</td>
                <td>Popup out animation of the toast.</td>
              </tr>
            </tbody>
          </Table>
        </div>

        <label
          style={{
            marginTop: "30px",
            marginBottom: "25px",
            fontWeight: "600",
            fontSize: "1.2rem",
          }}
        >
          Custom Toast Props
        </label>

        <div>
          <Table size="sm" responsive style={{ color: "rgba(0, 0, 0, 0.7)" }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Mandatory</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>position</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    top
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    middle
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    bottom
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    XX%
                  </label>
                </td>
                <td>no</td>
                <td>Popup out position of the toast.</td>
              </tr>
              <tr>
                <td>message</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    string
                  </label>
                </td>
                <td>yes</td>
                <td>Message to be displayed.</td>
              </tr>
              <tr>
                <td>backgroundColor</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    string
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    Colors[value]
                  </label>
                </td>
                <td>yes</td>
                <td>Background Color of toast.</td>
              </tr>
              <tr>
                <td>color</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    string
                  </label>
                </td>
                <td>yes</td>
                <td>Text Color of toast.</td>
              </tr>
              <tr>
                <td>toastIcon</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    JSX.Element
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    Font Awesome Icons
                  </label>
                </td>
                <td>no</td>
                <td>Icon indicating toast type.</td>
              </tr>
              <tr>
                <td>closeIcon</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    JSX.Element
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    Font Awesome Icons
                  </label>
                </td>
                <td>no</td>
                <td>Icon replacing default close icon.</td>
              </tr>
              <tr>
                <td>timer</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    number
                  </label>
                </td>
                <td>no</td>
                <td>Display time of toast in milliseconds.</td>
              </tr>
              <tr>
                <td>animate</td>
                <td>
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    fade-in-out
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    zoom-in-out
                  </label>
                  ,{" "}
                  <label
                    style={{
                      background: "rgba(76, 106, 220, 0.12)",
                      padding: "2px 4px 2px 4px",
                      borderRadius: "3px",
                    }}
                  >
                    stretch-in-out
                  </label>
                </td>
                <td>no</td>
                <td>Popup out animation of the toast.</td>
              </tr>
            </tbody>
          </Table>
        </div>

        <label
          id="Colors"
          style={{ marginTop: "40px", fontWeight: "600", fontSize: "1.8rem" }}
        >
          Colors
        </label>

        <SyntaxHighlighter
          showLineNumbers={false}
          showInlineLineNumbers={false}
          language="javascript"
          style={atomOneDark}
          className="highlighter"
        >
          {`import { Colors } from "vbocxjs";`}
        </SyntaxHighlighter>

        <div
          style={{
            width: "auto",
            height: "auto",
            display: "flex",
            padding: "20px 0px 30px 0px",
            flexDirection: "column",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <div>
            <b style={{ fontSize: "18px", color: "#566573" }}>Usage: </b>
            <b style={{ fontSize: "18px", color: "#1B2631" }}>
              Colors["{color}"]
            </b>
          </div>
          <div
            style={{
              width: "100%",
              height: "auto",
              padding: "18px 0px 30px 0px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <div style={{ margin: "0px 0px 10px 0px" }}></div>
            <div
              style={{
                width: "100%",
                height: "auto",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {Object.entries(Red).map(([key, value]) => {
                return (
                  <div
                    className="hovered-color"
                    style={{
                      cursor: "pointer",
                      width: "9.05%",
                      height: "50px",
                      backgroundColor: value,
                    }}
                    onClick={() => {
                      setColor(key);
                    }}
                  />
                );
              })}
            </div>

            <div
              style={{
                width: "100%",
                height: "auto",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {Object.entries(Pink).map(([key, value]) => {
                return (
                  <div
                    className="hovered-color"
                    style={{
                      cursor: "pointer",
                      width: "9.05%",
                      height: "50px",
                      backgroundColor: value,
                    }}
                    onClick={() => {
                      setColor(key);
                    }}
                  />
                );
              })}
            </div>

            <div
              style={{
                width: "100%",
                height: "auto",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {Object.entries(Purple).map(([key, value]) => {
                return (
                  <div
                    className="hovered-color"
                    style={{
                      cursor: "pointer",
                      width: "9.05%",
                      height: "50px",
                      backgroundColor: value,
                    }}
                    onClick={() => {
                      setColor(key);
                    }}
                  />
                );
              })}
            </div>

            <div
              style={{
                width: "100%",
                height: "auto",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {Object.entries(Blue).map(([key, value]) => {
                return (
                  <div
                    className="hovered-color"
                    style={{
                      cursor: "pointer",
                      width: "9.05%",
                      height: "50px",
                      backgroundColor: value,
                    }}
                    onClick={() => {
                      setColor(key);
                    }}
                  />
                );
              })}
            </div>

            <div
              style={{
                width: "100%",
                height: "auto",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {Object.entries(Green).map(([key, value]) => {
                return (
                  <div
                    className="hovered-color"
                    style={{
                      cursor: "pointer",
                      width: "9.05%",
                      height: "50px",
                      backgroundColor: value,
                    }}
                    onClick={() => {
                      setColor(key);
                    }}
                  />
                );
              })}
            </div>

            <div
              style={{
                width: "100%",
                height: "auto",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {Object.entries(Yellow).map(([key, value]) => {
                return (
                  <div
                    className="hovered-color"
                    style={{
                      cursor: "pointer",
                      width: "9.05%",
                      height: "50px",
                      backgroundColor: value,
                    }}
                    onClick={() => {
                      setColor(key);
                    }}
                  />
                );
              })}
            </div>

            <div
              style={{
                width: "100%",
                height: "auto",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {Object.entries(Orange).map(([key, value]) => {
                return (
                  <div
                    className="hovered-color"
                    style={{
                      cursor: "pointer",
                      width: "9.05%",
                      height: "50px",
                      backgroundColor: value,
                    }}
                    onClick={() => {
                      setColor(key);
                    }}
                  />
                );
              })}
            </div>

            <div
              style={{
                width: "100%",
                height: "auto",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {Object.entries(Gray).map(([key, value]) => {
                return (
                  <div
                    className="hovered-color"
                    style={{
                      cursor: "pointer",
                      width: "9.05%",
                      height: "50px",
                      backgroundColor: value,
                    }}
                    onClick={() => {
                      setColor(key);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>

        <div className="noteFA">
          Note: vbocxjs comes with Font Awesome v4.7 out of the box.
        </div>
      </div>
    </>
  );
}

export default Home;

const Green = {
  green: "#16a085",
  "green-50": "#e8f6f3",
  "green-100": "#d0ece7",
  "green-200": "#a2d9ce",
  "green-300": "#73c6b6",
  "green-400": "#45b39d",
  "green-500": "#16a085",
  "green-600": "#138d75",
  "green-700": "#117a65",
  "green-800": "#0e6655",
  "green-900": "#0b5345",
};

export default Green;

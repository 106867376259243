const Blue = {
  blue: "#3498db",
  "blue-50": "#ebf5fb",
  "blue-100": "#d6eaf8",
  "blue-200": "#aed6f1",
  "blue-300": "#85c1e9",
  "blue-400": "#5dade2",
  "blue-500": "#3498db",
  "blue-600": "#2e86c1",
  "blue-700": "#2874a6",
  "blue-800": "#21618c",
  "blue-900": "#1b4f72",
};

export default Blue;

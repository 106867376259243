const Gray = {
  gray: "#34495e",
  "gray-50": "#ebedef",
  "gray-100": "#d6dbdf",
  "gray-200": "#aeb6bf",
  "gray-300": "#85929e",
  "gray-400": "#5d6d7e",
  "gray-500": "#34495e",
  "gray-600": "#2e4053",
  "gray-700": "#283747",
  "gray-800": "#212f3c",
  "gray-900": "#1b2631",
};

export default Gray;

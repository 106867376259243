const Red = {
  red: "#c0392b",
  "red-50": "#f9ebea",
  "red-100": "#f2d7d5",
  "red-200": "#e6b0aa",
  "red-300": "#d98880",
  "red-400": "#cd6155",
  "red-500": "#c0392b",
  "red-600": "#a93226",
  "red-700": "#922b21",
  "red-800": "#7b241c",
  "red-900": "#641e16",
};

export default Red;

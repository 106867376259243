const Yellow = {
  yellow: "#f1c40f",
  "yellow-50": "#fef9e7",
  "yellow-100": "#fcf3cf",
  "yellow-200": "#f9e79f",
  "yellow-300": "#f7dc6f",
  "yellow-400": "#f4d03f",
  "yellow-500": "#f1c40f",
  "yellow-600": "#d4ac0d",
  "yellow-700": "#b7950b",
  "yellow-800": "#9a7d0a",
  "yellow-900": "#7d6608",
};

export default Yellow;

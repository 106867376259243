import React from "react";

function CustomBadge(props) {
  return (
    <>
      {props.type === "outline" ? (
        <div
          style={{
            display: "inline-block",
            WebkitUserSelect: "none",
            backgroundColor: "#FFFFFF00",
            color: props.color,
            border: `1px solid ${props.color}`,
            padding: "0px 3px 0px 3px",
            fontWeight: 500,
            fontSize: "0.72rem",
            borderRadius: "2px",
          }}
        >
          {props.message.toUpperCase()}
        </div>
      ) : (
        <div
          style={{
            display: "inline-block",
            WebkitUserSelect: "none",
            backgroundColor: props.backgroundColor,
            color: props.color,
            padding: "0px 3px 0px 3px",
            fontWeight: 500,
            fontSize: "0.72rem",
            borderRadius: "2px",
          }}
        >
          {props.message.toUpperCase()}
        </div>
      )}
    </>
  );
}

export default CustomBadge;

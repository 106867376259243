const Purple = {
  purple: "#8e44ad",
  "purple-50": "#f4ecf7",
  "purple-100": "#e8daef",
  "purple-200": "#d2b4de",
  "purple-300": "#bb8fce",
  "purple-400": "#a569bd",
  "purple-500": "#8e44ad",
  "purple-600": "#7d3c98",
  "purple-700": "#6c3483",
  "purple-800": "#5b2c6f",
  "purple-900": "#4a235a",
};

export default Purple;
